<!--司机余额详情-->
<template>
    <div>
        <el-row>
            <el-col :span="24">
                <div class="deriverBox">
                    <img src="../../../assets/img/search.png" />
                    <span>您正在查看司机：</span>
                    <span> {{ `${driverInfo.name}(商户号：${driverInfo.huifuId})` }}</span>
                </div>
            </el-col>
        </el-row>
        <el-form :inline="true" class="form" label-position="right">
            <el-form-item label="交易类型:" label-width="90px">
                <el-select v-model="search.businessType" placeholder="请选择" clearable>
                    <el-option v-for="(item, index) in accountApplyStatusList" :key="index" :label="item.name"
                        :value="item.no" />
                </el-select>
            </el-form-item>
            <el-form-item label="交易单号:">
                <el-input v-model="search.businessNo" placeholder="请输入交易单号"></el-input>
            </el-form-item>
            <el-form-item label="交易时间:">
                <el-date-picker v-model="TimeArr" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期" :picker-options="pickerOptions" :default-time="defaultTimeRange" clearable
                    format="yyyy-MM-dd HH:mm">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="headSearch">查询</el-button>
                <el-button type="info" @click="reset">重置</el-button>
            </el-form-item>
        </el-form>
        <table-compnent :records="tableData" :loading="loading" @searchHandler="doSearch">
            <template v-slot:content>
                <el-table-column prop="businessTypeName" label="交易类型"></el-table-column>
                <el-table-column prop="businessNo" label="交易单号"></el-table-column>
                <el-table-column label="交易状态">
                    <template slot-scope="scope">
                        <span
                            :style="{ 'color': scope.row.statusName.includes('成功') ? '#6BCA35' : scope.row.statusName.includes('失败') ? '#FF4D4F' : '#2C6AFF' }">{{
                                    scope.row.statusName
                            }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="交易金额(元)">
                    <template slot-scope="scope">
                        <span :style="{ 'color': scope.row.type == '2' ? '#FA8C16' : '#999999' }">{{
                                (scope.row.type == 2 ? '+' : '-') + scope.row.transactionAmount
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="balance" label="交易后余额(元)"></el-table-column>
                <el-table-column prop="opTimeStr" label="交易时间"></el-table-column>
            </template>
        </table-compnent>
    </div>
</template>
  
  <script>
import TableCompnent from "@/components/table/baseTablesComponents";
import TripFinancialApi from "@/api/TripFinancialApi";
import TripDriverApi from "@/api/TripDriverApi";
import { navTo, queryParam } from "@/utils/NavigatorUtils";
import { CommonDateFormat } from "@/utils/DateUtils";
export default {
    name: "TripEventLogList",
    components: {
        TableCompnent,
    },
    filters: {
        statusFilter(val) {
            if (val == 1) {
                return '已上车';
            } else if (val == 5) {
                return '待支付';
            } else if (val == 9) {
                return '已支付';
            }
            return '未知';
        }
    },
    data() {
        let that = this;
        return {
            tableData: {},
            driverNo: null,
            search: {
                businessNo: "",
                businessType: "",
                driverNo: "",
                startTimeStr: "",
                endTimeStr: "",
                pageNum: 1,
                pageSize: 20
            },
            driverInfo: {
                name:""
            },
            accountApplyStatusList: [],
            TimeArr: [],
            pickerMinDate: null,
            page: {},
            loading: false,
            defaultTimeRange: ["00:00:00", "00:00:00"],
            pickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    that.pickerMinDate = minDate.getTime();
                    if (maxDate) {
                        that.pickerMinDate = null;
                    }
                },
                disabledDate: (time) => {
                    if (that.pickerMinDate) {
                        const day30 = (30 - 1) * 24 * 3600 * 1000;
                        let maxTime = that.pickerMinDate + day30;
                        if (maxTime > new Date()) {
                            maxTime = new Date();
                            return (
                                time.getTime() > maxTime ||
                                time.getTime() < that.pickerMinDate - day30
                            );
                        } else {
                            return (
                                maxTime < time.getTime() ||
                                time.getTime() < that.pickerMinDate - day30
                            );
                        }
                    }

                    return time.getTime() > Date.now();
                },
            },
        };
    },
    created() {
        this.driverNo = this.$route.params.no;
        this.findAccountApplyStatusList();
        this.getDriverInfo();
    },
    methods: {
        reset() {
            this.search = this.$options.data().search;
            this.TimeArr = [];
            this.doSearch({ pageSize: 20, pageNum: 1 });
        },
        headSearch() {
            this.doSearch({ pageSize: 20, pageNum: 1 });
        },
        doSearch(params) {
            this.loading = true;
            if (params) {
                this.page = params;
                this.search.pageSize = this.page.pageSize;
                this.search.pageNum = this.page.pageNum;
            }
            if (this.TimeArr == null) {
                this.search.startTimeStr = "";
                this.search.endTimeStr = "";
            } else if (this.TimeArr && this.TimeArr.length != 0) {
                this.search.startTimeStr = CommonDateFormat(new Date(this.TimeArr[0]), 'YYYY-mm-dd HH:MM:SS');
                this.search.endTimeStr = CommonDateFormat(new Date(this.TimeArr[1]), 'YYYY-mm-dd HH:MM:SS');
            }
            this.search.driverNo = this.driverNo;
            TripFinancialApi.findDriverRecordList(this.search).then((resp) => {
                console.log(resp);
                if (resp.code === '200') {
                    this.tableData = resp.data;
                    this.loading = false;
                }
            });
        },
        findAccountApplyStatusList() {
            TripFinancialApi.findAccountApplyStatusSelect().then((resp) => {
                console.log(resp);
                if (resp.code === '200') {
                    this.accountApplyStatusList = resp.data;
                }
            });
        },

        getDriverInfo() {
            TripDriverApi.queryDriverByNo({ no: this.driverNo }).then(
                (resp) => {
                    if (resp.code == "200") {
                        this.driverInfo = resp.data;
                        console.log(this.driverInfo);
                    }
                }
            );
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep .el-form .el-input,
.el-select,
.el-date-editor {
    width: 280px;
}

.el-form-item {
    display: flex;
    justify-content: flex-end;
}

.deriverBox {
    display: flex;
    justify-content: flex-start;
    line-height: 30px;
    margin: 4px auto 24px 5px;

    img {
        width: 30px;
        height: 30px;
    }

    span {
        font-size: 16px;
        color: #666666;
        margin-left: 16px;
    }

    :last-child {
        color: #4F4D46;
        font-weight: bold;
        margin-left: 0;
    }
}

.form {
    display: grid;
    grid-template-columns: 370px auto auto 180px;
    grid-template-rows: auto;
}

::v-deep .el-form-item__label {
    white-space: nowrap;
    display: inline-block;
    color: #666666;
}
</style>